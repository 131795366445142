/* critters:include start */

@use '@angular/material' as mat;

$app-font-family: Open Sans;
$app-min-width: 240px;

html{
	height: 100%;
	min-width: $app-min-width;
	color-scheme: light dark;
	@include mat.theme((
		color: mat.$green-palette,
		typography: $app-font-family,
		density: 0
	));
	
}

html.app-dark-theme{color-scheme: dark;}

html.app-light-theme{color-scheme: light;}

body{
	margin: 0;
	//height: 100%;
	min-width: $app-min-width;
	font-family: $app-font-family, sans-serif;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within){
	border: 0 !important;
	clip: rect(0, 0, 0, 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	white-space: nowrap !important;
	width: 1px !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption){
	position: absolute !important;
}

a{
	white-space: nowrap;
	cursor: pointer;
	font-weight: bold;
	color: var(--mat-sys-primary)
}

a.wrapped{white-space: normal;}

a.no-decoration{text-decoration: none;}

a.white-space-normal{white-space: normal;}

.mat-sidenav-container{height: 100%;}

.app-elevation0{box-shadow: var(--mat-sys-level0) !important;}

.app-elevation1{box-shadow: var(--mat-sys-level1) !important;}

.app-elevation2{box-shadow: var(--mat-sys-level2) !important;}

.app-elevation3{box-shadow: var(--mat-sys-level3) !important;}

.app-elevation4{box-shadow: var(--mat-sys-level4) !important;}

.app-elevation5{box-shadow: var(--mat-sys-level5) !important;}

.app-sys-background{
	background: var(--mat-sys-background) !important;
}

.app-surface-dim{
	background: var(--mat-sys-surface-dim) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-surface-bright{
	background: var(--mat-sys-surface-bright) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-surface-container-lowest{
	background: var(--mat-sys-surface-container-lowest) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-surface-container-low{
	background: var(--mat-sys-surface-container-low) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-surface-container{
	background: var(--mat-sys-surface-container) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-surface-container-high{
	background: var(--mat-sys-surface-container-high) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-surface-container-highest{
	background: var(--mat-sys-surface-container-highest) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-12{font-size: 12px !important;}

.app-14{font-size: 14px !important;}

.app-16{font-size: 16px !important;}

.app-18{font-size: 18px !important;}

.app-20{font-size: 20px !important;}

.app-24{font-size: 24px !important;}

.app-30{font-size: 30px !important;}

.app-36{font-size: 36px !important;}

.app-48{font-size: 48px !important;}

.app-120{font-size: 120px !important;}

.app-150{font-size: 150px !important;}

.mat-icon svg{
	vertical-align: top !important;
}

.app-12.mat-icon,
.app-12.mdc-icon-button,
.app-12 .mat-icon,
.app-12 .mat-icon svg{
	font-size: 12px;height: 12px;width: 12px;padding: 0;
}

.app-14.mat-icon,
.app-14.mdc-icon-button,
.app-14 .mat-icon,
.app-14 .mat-icon svg{
	font-size: 14px;height: 14px;width: 14px;padding: 0;
}

.app-16.mat-icon,
.app-16.mdc-icon-button,
.app-16 .mat-icon,
.app-16 .mat-icon svg{
	font-size: 16px;height: 16px;width: 16px;padding: 0;
}

.app-18.mat-icon,
.app-18.mdc-icon-button,
.app-18 .mat-icon,
.app-18 .mat-icon svg{
	font-size: 18px;height: 18px;width: 18px;padding: 0;
}

.app-20.mat-icon,
.app-20.mdc-icon-button,
.app-20 .mat-icon,
.app-20 .mat-icon svg{
	font-size: 20px;height: 20px;width: 20px;padding: 0;
}

.app-24.mat-icon,
.app-24.mdc-icon-button,
.app-24 .mat-icon,
.app-24 .mat-icon svg{
	font-size: 24px;height: 24px;width: 24px;padding: 0;
}

.app-30.mat-icon,
.app-30.mdc-icon-button,
.app-30 .mat-icon,
.app-30 .mat-icon svg{
	font-size: 30px;height: 30px;width: 30px;padding: 0;
}

.app-36.mat-icon,
.app-36.mdc-icon-button,
.app-36 .mat-icon,
.app-36 .mat-icon svg{
	font-size: 36px;height: 36px;width: 36px;padding: 0;
}

.app-48.mat-icon,
.app-48.mdc-icon-button,
.app-48 .mat-icon,
.app-48 .mat-icon svg{
	font-size: 48px;height: 48px;width: 48px;padding: 0;
}

.app-120.mat-icon,
.app-120.mdc-icon-button,
.app-120.a,
.app-120 .mat-icon,
.app-120 .mat-icon svg{
	font-size: 120px;height: 120px;width: 120px;padding: 0;
}

.app-150.mat-icon,
.app-150.mdc-icon-button,
.app-150.a,
.app-150 .mat-icon,
.app-150 .mat-icon svg{
	font-size: 150px;height: 150px;width: 150px;padding: 0;
}

.app-primary{
	color: var(--mat-sys-primary) !important;
	background: var(--mat-sys-background) !important;
}
.app-secondary{
	color: var(--mat-sys-secondary) !important;
	background: var(--mat-sys-background) !important;
}
.app-error{
	color: var(--mat-sys-error) !important;
	background: var(--mat-sys-background) !important;
}

.app-primary-color{color: var(--mat-sys-primary) !important;}
.app-secondary-color{color: var(--mat-sys-secondary) !important;}
.app-error-color{color: var(--mat-sys-error) !important;}

.app-primary-background{
	color: var(--mat-sys-background) !important;
	background: var(--mat-sys-primary) !important;
}
.app-secondary-background{
	color: var(--mat-sys-background) !important;
	background: var(--mat-sys-secondary) !important;
}
.app-error-background{
	color: var(--mat-sys-background) !important;
	background: var(--mat-sys-error) !important;
}

.app-primary-color.mdc-button:disabled,
.app-primary-color.mat-mdc-fab:disabled,
.app-primary-background.mdc-button:disabled,
.app-primary-background.mat-mdc-fab:disabled{
	color: color-mix(in srgb, var(--mat-sys-on-surface) 38%, transparent) !important;
	background-color: color-mix(in srgb, var(--mat-sys-on-surface) 12%, transparent) !important;
}

.app-secondary-color.mdc-button:disabled,
.app-secondary-color.mat-mdc-fab:disabled,
.app-secondary-background.mdc-button:disabled,
.app-secondary-background.mat-mdc-fab:disabled{
	color: color-mix(in srgb, var(--mat-sys-on-surface) 38%, transparent) !important;
	background-color: color-mix(in srgb, var(--mat-sys-on-surface) 12%, transparent) !important;
}

.app-error-color.mdc-button:disabled,
.app-error-color.mat-mdc-fab:disabled,
.app-error-background.mdc-button:disabled,
.app-error-background.mat-mdc-fab:disabled{
	color: color-mix(in srgb, var(--mat-sys-on-surface) 38%, transparent) !important;
	background-color: color-mix(in srgb, var(--mat-sys-on-surface) 12%, transparent) !important;
}

mat-progress-bar.app-error{
	@include mat.progress-bar-overrides((
		active-indicator-color: var(--mat-sys-error),
		track-color: var(--mat-sys-error-container),
	));
}

.app-pointer{cursor: pointer !important;}

.dialog-responsive{width: 60%;}
.dialog-qrcode{
	margin-top: 0;
	height: 100%;
	width: 100%;
}
.dialog-qrcode .mat-mdc-dialog-content{
	max-height: 100%;
}
.dialog-qrcode-backdrop{
	background: rgba(0, 0, 0, .90) !important;
}

.tab-active.mdc-list-item{
	background: var(--mat-sys-surface-container) !important;
	color: var(--mat-sys-on-surface) !important;
}

.tab-active .mdc-list-item__primary-text{
	color: var(--mat-sys-primary) !important;
}

.app-dark-theme ckeditor{
	color: rgba(0, 0, 0, 0.87) !important;
}

.app-text-align-center{
	text-align: center;
}

.app-text-align-right{
	text-align: right;
}

.app-text-align-left{
	text-align: left;
}

.app-text-align-justify{
	text-align: justify;
}

.stretch-badge > .mat-badge-content{
	width: max-content !important;
	border-radius: 20px !important;
	padding: 0 5px !important;
}


.stretch-badge.cart-item-badge{
	padding-right: 10px;
}

.stretch-badge.product-price-badge{
	padding-right: 70px;
}

.discount-chip{
	text-decoration: line-through;
	font-style: italic;
	color: var(--mat-sys-error);
	margin:0;
}

.file-upload-drop-zone{}

.file-upload-drop-zone-content{position: relative;}

.file-upload-drop-zone .panel-glass{
	width: 100%;
	height: 100%;
	backdrop-filter: blur(2px) opacity(1) sepia(100%);
	position: absolute;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration-style: wavy;
	text-shadow: 0 0 10px black;
	color: white;
}

.ngx-file-drop__drop-zone--over .panel-glass{
	z-index: 2 !important;
}

.search-option span{
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}

.spacer{
	flex: 1 1 auto;
}

.product-detail-bread-crumbs{
	overflow: auto;
}

.product-detail-bread-crumbs div{
	flex-wrap: nowrap;
}

.product-search-title-container div{
	width: 100%;
}

.quantity-form-field .mat-mdc-form-field-infix{
	height: 38px !important;
	padding: 0 !important;
}
.quantity-form-field .mat-mdc-form-field-flex{
	height: 38px !important;
}
.quantity-form-field .mat-mdc-form-field-subscript-wrapper{
	height: 0 !important;
}
.app-dialog-title{
	padding-right: 56px !important;
}

.search-autocomplete-form-field .mat-mdc-form-field-subscript-wrapper{
	height: 0 !important;
}



//XXSmall
@media (max-width: 319.98px){
	.dialog-responsive{
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}
	mat-form-field{
		max-width: 100%;
	}
	.header-button-icon > .mat-badge-content{
		bottom: unset !important;
		top: 0 !important;
	}
	
	.quantity-increase .mat-mdc-button-touch-target{width:20px !important;}
	.quantity-decrease .mat-mdc-button-touch-target{width:20px !important;}
	.remove-item .mat-mdc-button-touch-target{width:24px !important;}
}

//XSmall
@media (min-width: 320px) and (max-width: 599.98px){
	.dialog-responsive{width: 90%;}
	mat-form-field{
		max-width: 100%;
	}
	.header-button-icon > .mat-badge-content{
		bottom: unset !important;
		top: 0 !important;
	}
}

//Small
@media (min-width: 600px) and (max-width: 959.98px){
	.dialog-responsive{width: 60%;}
	.header-button-icon > .mat-badge-content{
		bottom: unset !important;
		top: 0 !important;
	}
}

//Medium
@media (min-width: 960px) and (max-width: 1279.98px){
	.dialog-responsive{width: 40%;}
}

//Large
@media (min-width: 1280px) and (max-width: 1919.98px){
	.dialog-responsive{width: 40%;}
}

//XLarge
@media (min-width: 1920px){
	.dialog-responsive{width: 20%;}
}

//XSmallHeight
@media (max-height: 349.98px){
	.dialog-responsive{
		margin-top: 0 !important;
		height: 100%;
	}
	gallery{height: 100px !important;}
	gallery-thumbs{height: 30px !important;}
	.app-dialog-title{
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 16px !important;
		line-height: 1rem !important;
	}
	.app-dialog-content-title{
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 16px !important;
		line-height: 1rem !important;
	}
	.products-filters-inputs-container .mat-mdc-form-field-infix{
		height: 38px !important;
		min-height: 38px !important;
		padding: 0 !important;
	}
	.products-filters-inputs-container .mat-mdc-form-field-flex{
		height: 38px !important;
	}
	.products-filters-inputs-container .mat-mdc-form-field-subscript-wrapper{
		height: 0 !important;
	}
	.products-filters-inputs-container .mat-mdc-select-trigger{
		height: 38px !important;
	}
	.products-filters-search-inputs-container .mat-mdc-form-field-infix{
		height: 38px !important;
		min-height: 38px !important;
		padding: 0 !important;
	}
	.products-filters-search-inputs-container .mat-mdc-form-field-flex{
		height: 38px !important;
	}
	.products-filters-search-inputs-container .mat-mdc-form-field-subscript-wrapper{
		height: 0 !important;
	}
	.products-filters-search-inputs-container input{
		height: 38px !important;
	}
	.mat-expansion-panel-header,
	.mdc-list-item,
	.mdc-list-item span{
		height: 24px !important;
		min-height: 24px !important;
		font-size: 14px !important;
	}
	.search-autocomplete-panel .mat-expansion-panel-header,
	.search-autocomplete-panel .mdc-list-item,
	.search-autocomplete-panel .mdc-list-item span{
		height: 38px !important;
		min-height: 38px !important;
		font-size: 14px !important;
	}
	
	
}

//XSmallHeight and XXSmallWidth
@media (max-height: 349.98px) and (max-width: 319.98px){
	.products-filters{gap: 2px;}
	
}

/* critters:include end */



